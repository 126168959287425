import React from 'react'
import PropTypes from 'prop-types'

import { graphql } from 'gatsby'

// import BlogRoll from '../components/BlogRoll'
// import Features from '../components/Features'
import Layout from '../components/Layout'

// const HeadingDescriptionClass = 'dib w-100 w-auto-ns relative gray f5 v-btm'
const HeadingClass = 'dib relative w-100 measure-wide f2 mt0'

const ParagraphClass = 'dib w-100 relative gray f5'

// const InputClass =
//   'dib realtive w-100 pa3 bg-white ba b--black-10 black mb4 mb0-ns'

const Product = ({ product }) => (
  <div className="col-xs-12 col-md-3" key={Math.random()}>
    <img
      className="dib relative w-100 pa4"
      alt={product.title}
      src={
        !!product.image.childImageSharp
          ? product.image.childImageSharp.fluid.src
          : product.image
      }
    />
    <strong className="dib relative w-100 f4 b">{product.title}</strong>
    <p className="dib relative w-100 gray f6">{product.description}</p>
  </div>
)

export const IndexPageTemplate = ({
  subheading,
  retailers,
  products,
  classics,
  heading,
  contact,
  about
}) => (
  <div>
    {/* Header */}
    <section className="dib dt-ns vh-100-ns relative w-100 bg-black" id="home">
      <div className="dtc-ns v-mid h-100 white lh-copy">
        <div className="db center mw8 ph3 pv5 pv0-ns">
          <div className="row middle-xs">
            <div className="col-xs-12 col-md-7">
              <h1 className="dib relative w-100 f2 b">{heading}</h1>
              <h2 className="dib relative w-100 preline f5 f4-l fw4">
                {subheading}
              </h2>
              <p className="dib relative w-100">
                {contact.text}&nbsp;
                <a
                  className="inherit b underline link dim"
                  href={`mailto:${contact.link}`}
                >
                  {contact.linktext}
                </a>
                &nbsp;{contact.text2}
              </p>
            </div>
            <div className="col-xs-12 col-md-5">
              <img
                className="dib relative w-100"
                src={
                  !!classics.childImageSharp
                    ? classics.childImageSharp.fluid.src
                    : classics
                }
                alt="Tamari and Shiro Miso"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Products */}
    <section className="dib relative w-100 pv5 black lh-body" id="products">
      <div className="db center mw8 ph3">
        <h3
          className={HeadingClass}
          style={{
            maxWidth: `35rem`
          }}
        >
          {products.heading}
        </h3>
        <div className="dib flex-ns w-100 justify-between items-center">
          {/* <Link
            className="dib w-100 w-auto-ns link dim inherit b f6 underline v-btm"
            to="/products"
          >
            View all our products
          </Link> */}
        </div>
        <div className="dib relative w-100">
          {/* Sauces */}
          <div className="row top-xs">
            {products.sauces.map(object => (
              <Product product={object} key={Math.random()} />
            ))}
          </div>
          {/* Miso */}
          <div className="row top-xs">
            {products.miso.map(object => (
              <Product product={object} key={Math.random()} />
            ))}
          </div>
        </div>
      </div>
    </section>
    {/* About */}
    <section
      className="dib relative w-100 bg-brand-offwhite  f5 lh-body preline"
      id="about"
    >
      <div className="db center mw8 ph3 pv5">
        <h4 className={`${HeadingClass} mb4`}>{about.heading}</h4>
        <p className={ParagraphClass}>{about.description}</p>
        <h5 className={ParagraphClass}>{retailers.heading}:</h5>
        <div className="row middle-xs">
          {retailers.list.map(object => (
            <div className="col-xs-12 col-md-3" key={Math.random()}>
              <img
                className="dib relative w-100 pa3 v-mid"
                src={
                  !!object.image.childImageSharp
                    ? object.image.childImageSharp.fluid.src
                    : object.image
                }
                alt={object.name}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
    {/* Mailing list */}
    {/* <section className="dib relative w-100 f5" id="contact">
      <div className="db center mw8 ph3 pv5">
        <h6 className={`${HeadingClass} mb4`}>{mailinglist.heading}</h6>
        <div className="row items-center">
          <div className="col-xs-12 col-md-5">
            <input
              placeholder="Enter your name"
              className={InputClass}
              type="text"
            />
          </div>
          <div className="col-xs-12 col-md-5">
            <input
              placeholder="Enter your email"
              className={InputClass}
              type="email"
            />
          </div>
          <div className="col-xs-12 col-md-2">
            <button
              className="dib realtive w-100 pa3 bg-black ba b--black-10 white link dim pointer"
              type="submit"
            >
              Join
            </button>
          </div>
        </div>
      </div>
    </section> */}
  </div>
)

IndexPageTemplate.propTypes = {
  description: PropTypes.string,
  subheading: PropTypes.string,
  mailinglist: PropTypes.shape({
    heading: PropTypes.string
  }),
  retailers: PropTypes.shape({
    heading: PropTypes.string,
    list: PropTypes.array
  }),
  products: PropTypes.shape({
    sauces: PropTypes.array,
    miso: PropTypes.array
  }),
  classics: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  contact: PropTypes.shape({
    linktext: PropTypes.string,
    text2: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.string
  }),
  about: PropTypes.shape({
    description: PropTypes.string,
    heading: PropTypes.string
  })
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        description={frontmatter.description}
        mailinglist={frontmatter.mailinglist}
        subheading={frontmatter.subheading}
        retailers={frontmatter.retailers}
        products={frontmatter.products}
        classics={frontmatter.classics}
        heading={frontmatter.heading}
        contact={frontmatter.contact}
        image={frontmatter.image}
        about={frontmatter.about}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        classics {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mailinglist {
          heading
        }
        description
        subheading
        heading
        retailers {
          heading
          list {
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            name
          }
        }
        products {
          sauces {
            description
            image {
              childImageSharp {
                fluid(maxWidth: 360, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
          }
          miso {
            description
            image {
              childImageSharp {
                fluid(maxWidth: 360, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
          }
          description
          heading
        }
        contact {
          linktext
          text2
          text
          link
        }
        about {
          description
          heading
        }
      }
    }
  }
`
